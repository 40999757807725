// helper functions for m9 digital
// selecting elements
// select a list of matching elements, context is optional
function $all(selector, context) {
  return (context || document).querySelectorAll(selector);
} // select the first match only, context is optional


function $1(selector, context) {
  return (context || document).querySelector(selector);
} // has, add, remove Class


function hasClass(el, className) {
  return el.classList ? el.classList.contains(className) : new RegExp('\\b' + className + '\\b').test(el.className);
}

function addClass(el, className) {
  if (el.classList) el.classList.add(className);else if (!hasClass(el, className)) el.className += ' ' + className;
}

function removeClass(el, className) {
  if (el.classList) el.classList.remove(className);else el.className = el.className.replace(new RegExp('\\b' + className + '\\b', 'g'), '');
}

function toggleClass(el, className, callback) {
  el.classList.toggle(className);

  if (callback && callback.typeOf === 'function') {
    callback();
  }
} // debounced resize
// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.


function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
        args = arguments;

    var later = function later() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
} // get current screenSize


function currScreenSize() {
  var head = document.getElementsByTagName('head')[0];
  var computed;

  if (window.getComputedStyle) {
    computed = window.getComputedStyle(head, null);
  } else if (document.documentElement.currentStyle) {
    computed = head.currentStyle;
  }

  var size = computed.fontSize;
  size = parseInt(size, 10);
  var screenDef;

  if (size === 10) {
    // small screen
    screenDef = 'ss';
  } else if (size === 20) {
    // midi screen
    screenDef = 'ms';
  } else if (size === 30) {
    // large screen
    screenDef = 'ls';
  } else if (size === 40) {
    screenDef = 'xls';
  } else if (size === 50) {
    screenDef = 'xxl';
  } else if (size === 60) {
    screenDef = 'massive';
  }

  return screenDef;
}

function toggleAriaExpanded(element) {
  var attributeKey = 'aria-expanded';
  var currentAriaState = element.getAttribute(attributeKey);

  switch (currentAriaState) {
    case 'true':
      element.setAttribute(attributeKey, 'false');
      break;

    case 'false':
      element.setAttribute(attributeKey, 'true');
      break;

    default:
  }
}
function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

var ashHealthCustomScript = {};
var ashHealthStateObj = {
  smallScreenCategories: ['ss', 'ms', 'wsls'],
  largeScreenCategories: ['ls', 'xl', 'xxl', 'massive'],
  isSmallScreen: false,
  prefersReducedMotion: false
};
ashHealthCustomScript.start = {
  config: {
    menuActiveClass: 'is-menu-active'
  },
  // function references
  // declare function modules
  // function to kick things off, and allow for config object options if needed
  init: function init(settings) {
    // loop through any settings passed in, and overwrite the default config with those settings
    if (settings && _typeof(settings) === 'object') {
      for (var prop in settings) {
        if (settings.hasOwnProperty(prop)) {
          this.config[prop] = settings[prop];
        }
      }
    }

    this.initiateApplication();
    this.canHover();
    this.userPrefersReductionMotion();
    this.navigationControl();
    this.lettersAnimation();
  },
  // have left these couple of function here in the main block - they are
  // fairly generic to whole application
  initiateApplication: function initiateApplication() {//utility function here to add in any necessary classes etc to the dom on dom ready
  },
  canHover: function canHover() {
    var docEl = document.documentElement;

    if (window.matchMedia('(hover: hover)').matches) {
      addClass(docEl, 'is-hover-device');
    }
  },
  userPrefersReductionMotion: function userPrefersReductionMotion() {
    // function to check for prefers reduced motion being set by a user
    var QUERY = '(prefers-reduced-motion: no-preference)';
    var mediaQueryList = window.matchMedia(QUERY);

    function setPrefersReducedMotion() {
      ashHealthStateObj.prefersReducedMotion = !mediaQueryList.matches;

      if (!mediaQueryList.matches) {
        addClass(document.documentElement, 'prefers-reduced-motion');
      } else {
        removeClass(document.documentElement, 'prefers-reduced-motion');
      }
    } // check on site load if the user prefers reduced motion


    setPrefersReducedMotion(); //  also add listener in case user chooses as the site is opn in browser

    mediaQueryList.addListener(function () {
      setPrefersReducedMotion();
    });
  },
  navigationControl: function navigationControl() {
    var _this = this;

    // this function controls the main navigation for the ashfield health site
    var primaryNavTrigger = $1('.ash-h-menu-trigger');
    var primaryNavEl = $1('.ash-h-primary-nav-wrap');
    var siteHeader = $1('.ash-h-site-header'); // reference to object literal for scoping

    var _self = this; // all menu items to node list


    var menuItems = $all('.menu-item', primaryNavEl); // accessibility trap to provide circular tabbing for menu via keyboard when open

    function accessTrap(elParent) {
      var lastNavItem = $1('a', elParent);
      lastNavItem.addEventListener('blur', function () {
        if (hasClass(primaryNavEl, _self.config.menuActiveClass)) {
          primaryNavTrigger.focus();
        }
      });
    } // function to togglge active menu classes as required


    function toggleActiveNavClasses() {
      toggleClass(primaryNavEl, _self.config.menuActiveClass);
      toggleClass(primaryNavTrigger, _self.config.menuActiveClass);
      toggleClass(siteHeader, _self.config.menuActiveClass);
      var mq768 = window.matchMedia("(min-width: 769px)");
      var topmenu;

      if (mq768.matches) {
        topmenu = -640;
      } else {
        topmenu = -window.outerHeight;
      }

      if (hasClass(primaryNavEl, _self.config.menuActiveClass)) {
        var scrollY = window.scrollY;
        document.body.style.position = 'fixed';
        document.body.style.top = "-".concat(scrollY, "px");
        document.documentElement.style.scrollBehavior = 'auto';
        gsap.to(primaryNavEl, {
          top: 0,
          duration: 0.8,
          delay: 0.3,
          onComplete: function onComplete() {
            if (jQuery('.ash-h-primary-nav-wrap .sub-menu').length > 0) {
              if (!mq768.matches) {
                jQuery('.ash-h-primary-nav-wrap a').css({
                  'top': 0,
                  'opacity': 1
                });
              } else {
                jQuery('.ash-h-primary-nav-wrap a').css({
                  'top': 0,
                  'opacity': 1
                });
              }
            } else {
              jQuery('.ash-h-primary-nav-wrap a').css({
                'top': 0,
                'opacity': 1
              });
            }
          }
        });
      } else {
        if (jQuery('.ash-h-primary-nav-wrap .sub-menu').length > 0) {
          if (!mq768.matches) {
            jQuery('.ash-h-primary-nav-wrap a').css({
              'top': '61px',
              'opacity': 0
            });
          } else {
            jQuery('.ash-h-primary-nav-wrap a').css({
              'top': '73px',
              'opacity': 0
            });
          }
        } else {
          jQuery('.ash-h-primary-nav-wrap a').css({
            'top': '100%',
            'opacity': 0
          });
        }

        gsap.to(primaryNavEl, {
          top: topmenu,
          duration: 0.8,
          delay: 0.8
        });
        var _scrollY = document.body.style.top;
        document.body.style.position = '';
        document.body.style.top = '';
        window.scrollTo(0, parseInt(_scrollY || '0') * -1);
        document.documentElement.style.scrollBehavior = '';
      }
    } // loop through items in menu, and if we are at the last one, add an event listener so that the navigation can be properly
    // operated by a keyboard user


    for (var i = 0; i <= menuItems.length; i++) {
      var el = menuItems[i]; //console.log(i);

      if (i === menuItems.length - 1) {
        addClass(el, 'nav-last');
        accessTrap(el);
      }
    } // allow a user to press escape key to close menu if desired


    window.addEventListener('keyup', function (e) {
      if (hasClass(primaryNavEl, _this.config.menuActiveClass)) {
        if (e.key === 'Escape' || e.which === 27) {
          toggleActiveNavClasses();
        }
      }
    }); // click event for the navigation trigger

    if (primaryNavTrigger) {
      primaryNavTrigger.addEventListener('click', function (e) {
        e.preventDefault();
        toggleActiveNavClasses();
      });
    }
  },
  lettersAnimation: function lettersAnimation() {
    var lettersWrapper = $1('.letters-wrapper');
    var bodyLettersInner = $1('.body-letters-inner');
    var headerLettersInner = $1('.header-letters-inner');
    var siteFooter = $1('.site-footer');
    if (lettersWrapper === null) return; // letter elements

    var letterHd = $1('.letter-element-hd');
    var letterA = $1('.letter-element-bd-a');
    var letterB = $1('.letter-element-bd-b');
    var letterC = $1('.letter-element-bd-c');
    var letterD = $1('.letter-element-bd-d');
    var letterE = $1('.letter-element-bd-e');
    var letterF = $1('.letter-element-bd-f'); // letter triggers

    var pageLetterTrigger = $1('.page-blocks'); // header letter movement

    if (letterHd !== null) {
      ScrollTrigger.create({
        trigger: letterHd,
        toggleClass: "is-moving"
      }); //header letter parallax

      gsap.to(headerLettersInner, {
        yPercent: -25,
        ease: 'none',
        scrollTrigger: {
          trigger: letterHd,
          scrub: 3
        }
      });
    } // body letter movements


    if (letterA !== null) {
      if (jQuery('.home-hero').length > 0) {
        ScrollTrigger.create({
          trigger: letterA,
          start: 'top+=200% bottom',
          end: 'top+=300% top',
          toggleClass: "is-moving"
        });
      } else {
        ScrollTrigger.create({
          trigger: letterA,
          toggleClass: "is-moving"
        });
      }
    }

    if (letterB !== null) {
      if (jQuery('.home-hero').length > 0) {
        ScrollTrigger.create({
          trigger: letterB,
          start: 'top+=200% bottom',
          end: 'top+=300% top',
          toggleClass: "is-moving"
        });
      } else {
        ScrollTrigger.create({
          trigger: letterB,
          toggleClass: "is-moving"
        });
      }
    }

    if (letterC !== null) {
      if (jQuery('.home-hero').length > 0) {
        ScrollTrigger.create({
          trigger: letterC,
          start: 'top+=200% bottom',
          end: 'top+=300% top',
          toggleClass: "is-moving"
        });
      } else {
        ScrollTrigger.create({
          trigger: letterC,
          toggleClass: "is-moving"
        });
      }
    }

    if (letterD !== null) {
      if (jQuery('.home-hero').length > 0) {
        ScrollTrigger.create({
          trigger: letterD,
          start: 'top+=200% bottom',
          end: 'top+=300% top',
          toggleClass: "is-moving"
        });
      } else {
        ScrollTrigger.create({
          trigger: letterD,
          toggleClass: "is-moving"
        });
      }
    }

    if (letterE !== null) {
      if (jQuery('.home-hero').length > 0) {
        ScrollTrigger.create({
          trigger: letterE,
          start: 'top+=200% bottom',
          end: 'top+=300% top',
          toggleClass: "is-moving"
        });
      } else {
        ScrollTrigger.create({
          trigger: letterE,
          toggleClass: "is-moving"
        });
      }
    }

    if (letterF !== null) {
      if (jQuery('.home-hero').length > 0) {
        ScrollTrigger.create({
          trigger: letterF,
          start: 'top+=200% bottom',
          end: 'top+=300% top',
          toggleClass: "is-moving"
        });
      } else {
        ScrollTrigger.create({
          trigger: letterF,
          toggleClass: "is-moving"
        });
      }
    } // this creates the parallax on the container


    gsap.to(bodyLettersInner, {
      yPercent: -20,
      ease: 'none',
      scrollTrigger: {
        trigger: pageLetterTrigger,
        endTrigger: siteFooter,
        start: 'top center',
        end: 'top center',
        scrub: 3
      }
    });
  }
}; // dom ready

window.addEventListener('DOMContentLoaded', function () {
  // gsap plugins need to be registered - do this on dom ready, prior to calling the start function in the obj literal
  gsap.registerPlugin(CSSPlugin, CSSRulePlugin, ScrollToPlugin, ScrollTrigger, ExpoScaleEase, RoughEase, SlowMo); // this calls the init function from the object literal on dom ready, and kicks everything off.

  ashHealthCustomScript.start.init();
});
window.addEventListener('load', function () {// event listener can be used for triggering any required methods that are not critical on dom ready
  // helpful with 1st paint performance
});
var maskOne = document.querySelector("#mask-one");
var maskTwo = document.querySelector("#mask-two");
var maskThree = document.querySelector("#mask-three");
var tlmaskAnim1 = new TimelineMax({
  repeat: -1
});
var tlmaskAnim2 = new TimelineMax({
  repeat: -1
});
var tlmaskAnim3 = new TimelineMax({
  repeat: -1
});
var tlLayerOneAnim = new TimelineMax({
  repeat: -1
});
tlmaskAnim1.to(maskOne, 0.7, {
  y: '-=10',
  ease: Sine.easeInOut
}).to(maskOne, 0.7, {
  y: '+=10',
  ease: Sine.easeInOut
});
tlmaskAnim2.to(maskTwo, 5, {
  y: '-=15',
  ease: Sine.easeInOut
}).to(maskTwo, 5, {
  y: '+=15',
  ease: Sine.easeInOut
});
tlmaskAnim3.to(maskThree, 5, {
  x: '-=15',
  ease: Sine.easeInOut
}).to(maskThree, 5, {
  x: '+=15',
  ease: Sine.easeInOut
});